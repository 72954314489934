import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';
import '../Portfolyo/Portfolyo.css';
import { FaGithub } from 'react-icons/fa';

const PortfolioText = ({ title, subtitle, text, githubLink }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className='portfolio-text-container'>
      <h1 className='portfolio-title1'>{title}</h1>
      <h2 className='portfolio-title'>{subtitle}</h2>
      <p className={`portfolio-text ${expanded ? 'expanded' : ''}`} onClick={handleToggleExpand}>
        {text}
      </p>
      {githubLink && (
        <a href={githubLink} target='_blank' rel='noopener noreferrer' className='github-link'>
          <FaGithub size={24} /> GitHub
        </a>
      )}
    </div>
  );
};

const ProjectSlider = ({ images }) => {
  const NextArrow = (props) => (
    <div className="custom-arrow custom-arrow-right" onClick={props.onClick}>
      <IoIosArrowDroprightCircle size={33} />
    </div>
  );

  const PrevArrow = (props) => (
    <div className="custom-arrow custom-arrow-left" onClick={props.onClick}>
      <IoIosArrowDropleftCircle size={33} />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className='slider-container'>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Ekran ${index + 1}`} className='portfolio-img' />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const Portfolio = () => {
  const navigate = useNavigate();

  const firstProjectImages = [
    '/w/login.png',
    '/w/home.png',
    '/w/adalar.png',
    '/w/politika.png',
    '/w/search.png',
    '/w/rez.png',
    '/w/onay.png',
    '/w/sato.png',
    '/w/kategori.png',
    '/w/profil.png',
  ];

  const secondProjectImages = [
    '/t/1.png',
    '/t/2.png',
    '/t/3.png',
    '/t/4.png',
    '/t/5.png',
    '/t/6.png',
    '/t/7.png',
    '/t/8.png',
  ];

  return (
    <div className='portfolio-container'>
      <div className='back-button' onClick={() => navigate(-1)}>
        <TiArrowBack size={40} />
      </div>

      <div className='project-section'>
        <div className='slider-container'>
          <ProjectSlider images={firstProjectImages} />
        </div>
        <PortfolioText
          title='Wanderly'
          subtitle='React Native/TypeScript'
          githubLink='https://github.com/merveaydincode/hotel-wanderly.git'
        />
      </div>

      <div className='project-section'>
        <div className='slider-container'>
          <ProjectSlider images={secondProjectImages} />
        </div>
        <PortfolioText
          title='Tedarika'
          subtitle='React Native/TypeScript'
          githubLink='https://github.com/Tedarikatr/tedarika_react_mobile.git'
        />
      </div>
    </div>
  );
};

export default Portfolio;
